
export default {
  name: 'NFTFAQItem',
  props: {
    number: {
      type: Number,
      default: 1,
    },
    isInitialOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.isInitialOpen,
    };
  },
  methods: {
    onEnter(el, done) {
      this.$gsap.gsap.from(el, {
        height: 0,
        duration: 0.25,
        clearProps: 'height',
        onComplete: () => {
          done();
        },
      });
    },
    onLeave(el, done) {
      this.$gsap.gsap.to(el, {
        height: 0,
        duration: 0.15,
        onComplete: () => {
          done();
        },
      });
    },
  },
};
