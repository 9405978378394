import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6bd5e1ba"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SiteHeader: require('/home/runner/work/liker-land/liker-land/src/components/SiteHeader.vue').default,NFTBookItemCardV2: require('/home/runner/work/liker-land/liker-land/src/components/NFTBook/ItemCardV2.vue').default,ButtonV2: require('/home/runner/work/liker-land/liker-land/src/components/ButtonV2.vue').default,NFTBookItemCard: require('/home/runner/work/liker-land/liker-land/src/components/NFTBook/ItemCard.vue').default,Dialog: require('/home/runner/work/liker-land/liker-land/src/components/Dialog.vue').default,IndexPageFAQItem: require('/home/runner/work/liker-land/liker-land/src/components/IndexPage/FAQItem.vue').default})
