import { render, staticRenderFns } from "./SpecTableItemAccessMethod.vue?vue&type=template&id=0d4cfbea"
import script from "./SpecTableItemAccessMethod.vue?vue&type=script&lang=js"
export * from "./SpecTableItemAccessMethod.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NFTBookSpecTableLabel: require('/home/runner/work/liker-land/liker-land/src/components/NFTBook/SpecTableLabel.vue').default})
