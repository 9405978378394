
import { getContentUrlType } from '~/util/misc';
import { ellipsis } from '~/util/ui';

import nftMixin from '~/mixins/nft';

const PRESET_TYPE = {
  SHELF: 'shelf', // (Landing page) shelf style
  CAMPAIGN: 'campaign', // (Landing page) like-green bg
  COMPACT: 'compact', // (Landing page) compact style
  COVER: 'cover', // Cover style
  DEFAULT: 'default', // (All books page) white bg
  DETAILS: 'details', // (Class details page) Expand all information
};

const THEME_TYPE = {
  DEFAULT: 'default',
  FUTURE: 'future',
};

export default {
  filters: {
    ellipsis,
  },
  mixins: [nftMixin],
  props: {
    isLazyLoaded: {
      type: Boolean,
      default: true,
    },
    classId: {
      type: String,
      required: true,
    },
    preset: {
      type: String,
      default: PRESET_TYPE.DEFAULT,
    },
    theme: {
      type: String,
      default: THEME_TYPE.DEFAULT,
    },
    shelfClass: {
      type: [Array, String],
      default: () => [],
    },
    imageSrc: {
      type: String,
      default: '',
    },
    videoSrc: {
      type: String,
      default: '',
    },
    shouldResizeSrc: {
      type: Boolean,
      default: true,
    },
    isLinkDisabled: {
      type: Boolean,
      default: false,
    },
    componentClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    creatorDisplayName() {
      return (
        this.getUserInfoByAddress(this.iscnOwner)?.displayName || this.iscnOwner
      );
    },
    isShelfPreset() {
      return (
        this.preset === PRESET_TYPE.SHELF || this.preset === PRESET_TYPE.COVER
      );
    },
    isDetailsPreset() {
      return this.preset === PRESET_TYPE.DETAILS;
    },
    isCompactPreset() {
      return this.preset === PRESET_TYPE.COMPACT;
    },
    isCoverPreset() {
      return this.preset === PRESET_TYPE.COVER;
    },
    isFutureTheme() {
      return this.theme === THEME_TYPE.FUTURE;
    },
    isNew() {
      // True if within 30 days
      return (
        new Date().getTime() - new Date(this.iscnData?.recordTimestamp) <
        1000 * 60 * 60 * 24 * 30
      );
    },
    contentTypes() {
      const types = [];
      this.iscnContentUrls.forEach(url => {
        types.push(getContentUrlType(url));
      });
      return [...new Set(types.filter(type => type !== 'unknown'))];
    },
    isLinkComponent() {
      return !this.isLinkDisabled && !this.isDetailsPreset;
    },
    componentTag() {
      if (!this.isLinkComponent) return 'div';
      return 'NuxtLink';
    },
    componentProps() {
      if (!this.isLinkComponent) return {};
      return {
        to: this.nftCollectRoute,
      };
    },
    bgStyle() {
      if (this.isFutureTheme) {
        return '';
      }
      switch (this.preset) {
        case PRESET_TYPE.CAMPAIGN:
          return 'bg-like-green hover:shadow-lg';
        case PRESET_TYPE.COMPACT:
        case PRESET_TYPE.DEFAULT:
          return 'bg-white hover:shadow-md';
        case PRESET_TYPE.DETAILS:
          return 'bg-white';
        default:
          return '';
      }
    },
    coverClasses() {
      if (this.isFutureTheme) {
        return [];
      }
      const classes = ['shadow-lg'];
      if (!this.isDetailsPreset) {
        classes.push(
          this.isShelfPreset ? 'hover:shadow-2xl' : 'group-hover:shadow-2xl',
          this.isShelfPreset ? 'hover:scale-105' : 'group-hover:scale-105',
          'active:scale-100',
          'transition-all'
        );
      }
      return classes;
    },
    titleStyle() {
      if (this.preset === PRESET_TYPE.CAMPAIGN) return 'text-white';
      return 'text-dark-gray';
    },
    descriptionStyle() {
      switch (this.preset) {
        case PRESET_TYPE.CAMPAIGN:
          return 'text-like-cyan-pale line-clamp-2';
        case PRESET_TYPE.COMPACT:
        case PRESET_TYPE.DEFAULT:
          return 'text-dark-gray line-clamp-2';
        case PRESET_TYPE.DETAILS:
          return 'text-dark-gray';
        default:
          return '';
      }
    },
    displayNameStyle() {
      if (this.preset === PRESET_TYPE.CAMPAIGN) return 'text-white';
      return 'text-dark-gray';
    },
    bookDescription() {
      const overrideKey = `nft_override_${this.classId}_description`;
      const hasOverride = this.$te(overrideKey);
      if (hasOverride) return this.$t(overrideKey);
      return this.iscnDescription || this.NFTDescription || '';
    },
    bookDescriptionTrimmed() {
      if (this.preset === PRESET_TYPE.CAMPAIGN) {
        return this.bookDescription
          .replaceAll('\n', '')
          .trim()
          .substring(0, 100);
      }
      return this.bookDescription.trim();
    },
  },
  mounted() {
    if (!this.isLazyLoaded) this.fetchInfo();
  },
  methods: {
    async fetchInfo() {
      await this.lazyFetchNFTClassAggregatedData();
    },
    onClickAvatar() {
      this.$emit('click-avatar', this.iscnOwner);
    },
  },
};
