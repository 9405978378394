import { render, staticRenderFns } from "./ItemCard.vue?vue&type=template&id=2d926644"
import script from "./ItemCard.vue?vue&type=script&lang=js"
export * from "./ItemCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NFTCover: require('/home/runner/work/liker-land/liker-land/src/components/NFTCover.vue').default,NFTBookCover: require('/home/runner/work/liker-land/liker-land/src/components/NFTBook/Cover.vue').default,Label: require('/home/runner/work/liker-land/liker-land/src/components/Label.vue').default,NFTBookSpecTableLabel: require('/home/runner/work/liker-land/liker-land/src/components/NFTBook/SpecTableLabel.vue').default,Identity: require('/home/runner/work/liker-land/liker-land/src/components/Identity/Identity.vue').default,NFTBookSpecTable: require('/home/runner/work/liker-land/liker-land/src/components/NFTBook/SpecTable.vue').default,NFTBookSpecTableItemAvailableFormat: require('/home/runner/work/liker-land/liker-land/src/components/NFTBook/SpecTableItemAvailableFormat.vue').default,NFTBookSpecTableItemAccessMethod: require('/home/runner/work/liker-land/liker-land/src/components/NFTBook/SpecTableItemAccessMethod.vue').default})
