
import { getImageResizeAPI } from '~/util/api';

export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: 'div',
    },
    coverClass: {
      type: String,
      default: '',
    },
    coverResize: {
      type: Number,
      default: 300,
    },
    to: {
      type: Object,
      default: null,
    },
    classAspectRatio: {
      type: String,
      default: 'aspect-[4/5]',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    bgImageSrc() {
      return getImageResizeAPI(this.src, { width: 4 });
    },
    backgroundStyle() {
      const style = {};
      if (this.backgroundColor) {
        style.backgroundColor = this.backgroundColor;
      } else {
        style.backgroundImage = `url(${this.bgImageSrc})`;
      }
      return style;
    },
  },
};
