
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

import bookstoreMixin from '~/mixins/bookstore';

import { logTrackerEvent } from '~/util/EventLogger';

export default {
  name: 'IndexV2',
  components: {
    Swiper,
    SwiperSlide,
  },
  mixins: [bookstoreMixin],
  layout: 'index',
  data() {
    return {
      dialogNFTClassList: [],
      isSiteHeaderFixed: false,
    };
  },
  async fetch({ store }) {
    try {
      await store.dispatch('fetchBookstoreItemsFromCMSForLandingPage');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
  head() {
    const title = this.$t('og_title');
    const description = this.$t('og_description');

    const classIds = Array.from(
      new Set(this.bookstoreItems.map(b => b.classIds || b.classId).flat())
    );
    const links = [];
    classIds.forEach(classId =>
      links.push({
        rel: 'prefetch',
        href: `/api/nft/metadata?class_id=${classId}`,
      })
    );

    const meta = [
      {
        hid: 'og:title',
        property: 'og:title',
        content: title,
      },
      {
        hid: 'description',
        name: 'description',
        content: description,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description,
      },
    ];

    const script = [
      {
        hid: 'schema',
        innerHTML: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity: this.faqs.map(({ question, answer }) => ({
            '@type': 'Question',
            name: question,
            acceptedAnswer: {
              '@type': 'Answer',
              text: answer,
            },
          })),
        }),
        type: 'application/ld+json',
      },
      {
        hid: 'elfsight',
        src: 'https://apps.elfsight.com/p/platform.js',
        defer: true,
        dataUseServiceCore: 'data-use-service-core',
      },
    ];

    return {
      title,
      meta,
      link: links,
      script,
    };
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en';
    },
    paddingClass() {
      return 'px-[16px] laptop:px-[32px] desktop:px-[48px]';
    },
    heroGraphicPositionClass() {
      return [
        'absolute',
        'left-[70%]',
        'desktop:left-[60%]',
        'top-[30%]',
        'translate-y-[-120%] desktop:translate-y-[-50%]',
        'translate-x-[-50%]',
        'pointer-events-none',
      ].join(' ');
    },
    bookstoreSectionStickyClass() {
      return 'desktop:sticky desktop:top-[124px]';
    },
    bookstoreItems() {
      return this.nftBookstoreItemsFromCMSForLandingPage.filter(
        item =>
          !item.locales ||
          item.locales.some(locale => this.$i18n.locale.includes(locale))
      );
    },
    stickyBookstoreItem() {
      return this.bookstoreItems[0];
    },
    bookstoreItemsInGrid() {
      return this.bookstoreItems.slice(1, 7);
    },
    faqs() {
      return this.$t('index_faq_list').map(({ q: question, a: answer }) => ({
        question,
        answer,
      }));
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (!this.$refs.hero) return;

      const { clientHeight: heroHeight } = this.$refs.hero;
      this.isSiteHeaderFixed = window.scrollY > heroHeight;

      if (
        !this.hasScrolledHitBottom &&
        window.scrollY >= document.body.scrollHeight - window.screen.availHeight
      ) {
        this.hasScrolledHitBottom = true;
        logTrackerEvent(this, 'IndexPage', 'IndexScrollHitBottom', '', 1);
      } else if (!this.hasScrolledPastHero && this.isSiteHeaderFixed) {
        this.hasScrolledPastHero = true;
        logTrackerEvent(this, 'IndexPage', 'IndexScrollPassHero', '', 1);
      }
    },
    handleClickAboutNFTBook() {
      logTrackerEvent(this, 'IndexPage', 'IndexClickAboutNFTBook', '', 1);
    },
    handleClickItem(event, item) {
      if (item.isMultiple) {
        event.preventDefault();
        this.dialogNFTClassList = item.classIds;
        logTrackerEvent(
          this,
          'IndexPage',
          'IndexNFTBookItemCardClick',
          item.classIds[0],
          1
        );
      } else {
        logTrackerEvent(
          this,
          'IndexPage',
          'IndexNFTBookItemCardClick',
          item.classId,
          1
        );
      }
    },
    handleSwiperBookstoreSwiper() {
      if (this.hasSwipedBookstoreSwiper) return;
      this.hasSwipedBookstoreSwiper = true;
      logTrackerEvent(this, 'IndexPage', 'IndexSwipeBookstoreSwiper', '', 1);
    },
    handleClickBookstoreMore() {
      logTrackerEvent(this, 'IndexPage', 'IndexClickBookstoreMore', '', 1);
    },
    handleClickFeatureSupportAuthor() {
      logTrackerEvent(
        this,
        'IndexPage',
        'IndexClickFeatureSupportAuthor',
        '',
        1
      );
    },
    handleClickFeatureOwnership() {
      logTrackerEvent(this, 'IndexPage', 'IndexClickFeatureOwnership', '', 1);
    },
    handleClickFeatureCommunity() {
      logTrackerEvent(this, 'IndexPage', 'IndexClickFeatureCommunity', '', 1);
    },
    handleClickCategoryEbook() {
      logTrackerEvent(this, 'IndexPage', 'IndexClickCategoryEbook', '', 1);
    },
    handleClickSocialFollow() {
      logTrackerEvent(this, 'IndexPage', 'IndexClickSocialFollow', '', 1);
    },
    handleClickDocs() {
      logTrackerEvent(this, 'IndexPage', 'IndexClickDocs', '', 1);
    },
    handleClickFAQ() {
      logTrackerEvent(this, 'IndexPage', 'IndexClickFAQ', '', 1);
    },
    closeMultipleNFTClassDialog() {
      this.dialogNFTClassList = [];
    },
  },
};
