
export default {
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    text: {
      type: String,
      default: '',
    },
  },
};
