
import nftOrCollectionMixin from '~/mixins/nft-or-collection';

import { ellipsis } from '~/util/ui';

export default {
  filters: {
    ellipsis,
  },
  mixins: [nftOrCollectionMixin],
  props: {
    itemId: {
      type: String,
      required: true,
    },
    classCoverFrameAspectRatio: {
      type: String,
      default: 'aspect-[4/5]',
    },
    coverResize: {
      type: Number,
      default: 300,
    },
    isLinkDisabled: {
      type: Boolean,
      default: false,
    },
    isLazyLoaded: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classId() {
      return this.itemId.startsWith('likenft1') ? this.itemId : '';
    },
    collectionId() {
      return this.itemId.startsWith('col') ? this.itemId : '';
    },
    creatorDisplayName() {
      return (
        this.getUserInfoByAddress(this.productOwner)?.displayName ||
        this.productOwner
      );
    },
  },
  mounted() {
    if (!this.isLazyLoaded) this.fetchInfo();
  },
  methods: {
    async fetchInfo() {
      if (this.isCollection) {
        await this.lazyFetchNFTCollectionInfo();
      } else {
        await this.lazyFetchNFTClassAggregatedData();
      }
    },
  },
};
