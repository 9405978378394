
import { getImageResizeAPI } from '~/util/api';

const coverPlaceholderSrc = require('~/assets/images/nft/primitive-nft.jpg');

export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: 'Cover',
    },
    imgClass: {
      type: String,
      default: '',
    },
    resize: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      isPortrait: true,
      isError: false,
      isLoaded: false,
    };
  },
  computed: {
    isShowPlaceholder() {
      return !this.src || !this.isLoaded || this.isError;
    },
    wrapperClasses() {
      return [
        'relative',
        'rounded-[4px]',
        'overflow-hidden',
        this.isPortrait ? 'h-full' : 'w-full',
        {
          '[box-shadow:-8px_42px_64px_0px_rgba(0,_0,_0,_0.12)]': this.isLoaded,
        },
      ];
    },
    coverClass() {
      return [
        this.isPortrait ? 'h-full' : 'w-full',
        this.imgClass,
        'max-w-[inherit]',
        'max-h-[inherit]',
        'object-cover',
        'transition-opacity transition-transform',
        {
          'opacity-0 scale-2': !this.isLoaded,
        },
      ];
    },
    resizedSrc() {
      return this.isError
        ? coverPlaceholderSrc
        : getImageResizeAPI(this.src, { width: this.resize });
    },
  },
  watch: {
    src() {
      this.isPortrait = true;
      this.isError = false;
      this.isLoaded = false;
    },
  },
  methods: {
    handleMediaLoad() {
      const imgEl = this.$refs.img;
      if (imgEl) {
        this.isPortrait = imgEl.naturalHeight > imgEl.naturalWidth;
      }
      this.isLoaded = true;
    },
    handleImageError() {
      this.isError = true;
      this.isLoaded = true;
    },
  },
};
